import { Listbox, Transition } from '@headlessui/react'
import { Fragment, useState } from 'react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/24/outline'
import Image from 'next/image'
import InfoModal from './infoModal'
import FaqModal from './faqModal'

import { useSession } from '../providers/session'
import { useBasket } from '../providers/basket'

export default function Card({
  service,
}) {

  const [selectedPrice, setSelectedPrice] = useState(
    `${service.pricing[0]?.data.label} £${
      service.pricing[0]?.data.price / 100
    }`
  )

  const [openInfoModal, setOpenInfoModal] = useState(false)
  const [openFaqModal, setOpenFaqModal] = useState(false)

    const { addItem } = useBasket()

  return (
    <>
      <div className="flex flex-1 flex-col justify-between">
        <div
          className="relative flex aspect-w-1 aspect-h-1 items-center justify-center rounded-2xl px-6 shadow-lg"
          style={{
            backgroundImage:
              'conic-gradient(from 90deg at 50% 50%, rgba(170, 133, 70, 1) 0%, rgba(209, 185, 143, 1) 50%, rgba(170, 133, 70, 1) 100%)',
          }}
        >
          <div className="absolute flex inset-0 w-full overflow-hidden rounded shadow-sm">
            <Image
              className="h-full w-full object-cover object-center"
              src={
                service.media[0].asset.url.startsWith('http')
                  ? service.media[0].asset.url
                  : service.media[1]?.asset.url.startsWith('http')
                  ? service.media[1].asset.url
                  : `https://charmelle.london${service.media[0].asset.url}`
              }
              layout="fill"
              alt={`${service.title} at Charmelle London Harley Street`}
            />
          </div>
        </div>

        <div className="relative flex flex-col  justify-center rounded-2xl px-2 pb-2">
          <h3 className="mt-2 text-base font-medium tracking-tight text-slate-900 before:mb-2 before:block before:font-mono before:text-sm before:text-slate-500">
            {service.title}
          </h3>
          <div className="flex-1">
            <p className="mt-2 overflow-auto text-sm text-slate-600 line-clamp-2">
              {service.content.body}
            </p>
            <button
              onClick={() => setOpenInfoModal(true)}
              className="group relative flex w-full items-center justify-between py-1 text-left"
            >
              <span className="mt-1 text-sm text-slate-600 underline">
                More information
              </span>
            </button>
          </div>
          {service.pricing && service.pricing.length > 0 && (
            <div className="my-2">
              <Listbox value={selectedPrice} onChange={setSelectedPrice}>
                <div className="relative mt-1">
                  <Listbox.Button className="focus-visible:ring-offset-black-300 relative w-full cursor-default rounded-lg border-2 bg-white py-2 pl-3 pr-10 text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 sm:text-sm">
                    <span className="block truncate">{selectedPrice}</span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                      <ChevronUpDownIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </span>
                  </Listbox.Button>
                  <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="absolute z-50 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                      {service.pricing.map((price) => (
                        <Listbox.Option
                          key={price.data.label}
                          className={({ active }) =>
                            `relative cursor-default select-none py-2 pl-10 pr-4 ${
                              active
                                ? 'bg-brand-600 text-white'
                                : 'text-gray-900'
                            }`
                          }
                          value={`${price.data.label} £${
                            price.data.price / 100
                          }`}
                        >
                          {({ selected }) => (
                            <>
                              <span
                                className={`block truncate ${
                                  selected ? 'font-medium' : 'font-normal'
                                }`}
                              >
                                {`${price.data.label} £${
                                  price.data.price / 100
                                }`}
                              </span>
                              {selected ? (
                                <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                                  <CheckIcon
                                    className="h-5 w-5 text-black"
                                    aria-hidden="true"
                                  />
                                </span>
                              ) : null}
                            </>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </Listbox>
            </div>
          )}
          <div className="flex justify-between space-x-3">
            <button
              disabled={false}
              onClick={() => addItem(service)}
              className={
                'group mt-3 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-brand-600 px-6 py-3 text-base font-serif text-white shadow transition-colors duration-100 hover:bg-brand-700 focus:outline-none focus:ring-2 focus:ring-brand-500 focus:ring-offset-2'
              }
            >
              Add to basket
            </button>
          </div>
        </div>
      </div>
      <InfoModal
        openInfoModal={openInfoModal}
        setOpenInfoModal={setOpenInfoModal}
        openFaqModal={openFaqModal}
        setOpenFaqModal={setOpenFaqModal}
        title={service.title}
        content={service.content}
        faqs={service.content.faqs}
        />
      <FaqModal
        openFaqModal={openFaqModal}
        setOpenFaqModal={setOpenFaqModal}
        setOpenInfoModal={setOpenInfoModal}
        title={service.title}
        content={service.content.faqs}
      />
    </>
  )
}
