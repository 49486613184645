import { Container } from 'components/Container'
import Card from './Card'

const catMap = {
  'b3374d67-fdf7-4ce3-8721-c0b4ff56ef0b': 'Body Treatments',
  '1588251b-6113-4cd8-ad82-e62770813826': 'Skin Booster Treatments',
  'e489c4b4-592c-42d9-adaf-aa4688d339e2': 'Facial & Skin Treatments',
  '7b478c68-1283-4973-8697-fbdb4df7299f': 'Dermal Fillers',
  '12491cfc-4419-4abb-b2ed-05390910a059': 'Treatment Packages',
}

export function Screencasts({ data }) {
  const services = data

  const categories = services.reduce((a, b) => {
    const { service } = b
    return {
      ...a,
      [service.category.id]: [...(a[service.category.id] || []), service],
    }
  }, {})

  console.log('categories', categories)

  return (
    <section
      id="treatments"
      aria-labelledby="treatments-title"
      className="z-1 relative scroll-mt-14 py-16 shadow sm:scroll-mt-32 sm:py-20 lg:py-12"
    >
      <Container>
        <h3 className="sr-only font-serif text-4xl text-slate-900 h-0">
          Harley Street Treatments From Charmelle London
        </h3>
        <div className="aspect-w-16 aspect-h-9 mx-auto rounded overflow-hidden shadow-lg">
          <video
            src="https://charmelle.london/videos/natural-enhancements-at-charmelle-london.mp4"
            playsInline
            controls
            muted
            controlsList="nodownload"
            // autoPlay
            poster="https://charmelle.london/images/poster.png"
            className="w-full overflow-hidden rounded-md object-fill"
          ></video>
        </div>
      </Container>
      <Container size="lg" className="mt-12">
        {catMap['7b478c68-1283-4973-8697-fbdb4df7299f'] && (
          <h4 className="mb-6 font-serif text-4xl text-slate-900">
            {catMap['7b478c68-1283-4973-8697-fbdb4df7299f']}
          </h4>
        )}
        <ol
          role="list"
          className="grid grid-cols-1 gap-y-10 gap-x-8 [counter-reset:video] sm:grid-cols-2 lg:grid-cols-4"
        >
          {categories['7b478c68-1283-4973-8697-fbdb4df7299f'].map((data) => {
            return (
              <li
                key={data.id}
                className="flex flex-col rounded-xl shadow-md [counter-increment:video]"
              >
                <Card service={data} />
              </li>
            )
          })}
        </ol>
      </Container>

      <Container size="lg" className="mt-16">
        {catMap['1588251b-6113-4cd8-ad82-e62770813826'] && (
          <h4 className="mb-6 font-serif text-4xl text-slate-900">
            {catMap['1588251b-6113-4cd8-ad82-e62770813826']}
          </h4>
        )}
        <ol
          role="list"
          className="grid grid-cols-1 gap-y-10 gap-x-8 [counter-reset:video] sm:grid-cols-2 lg:grid-cols-4"
        >
          {categories['1588251b-6113-4cd8-ad82-e62770813826'].map((data) => {
            return (
              <li
                key={data.id}
                className="flex flex-col rounded-xl shadow-md [counter-increment:video]"
              >
                <Card service={data} />
              </li>
            )
          })}
        </ol>
      </Container>

      <Container size="lg" className="mt-16">
        {catMap['e489c4b4-592c-42d9-adaf-aa4688d339e2'] && (
          <h4 className="mb-6 font-serif text-4xl text-slate-900">
            {catMap['e489c4b4-592c-42d9-adaf-aa4688d339e2']}
          </h4>
        )}
        <ol
          role="list"
          className="grid grid-cols-1 gap-y-10 gap-x-8 [counter-reset:video] sm:grid-cols-2 lg:grid-cols-4"
        >
          {categories['e489c4b4-592c-42d9-adaf-aa4688d339e2'].map((data) => {
            return (
              <li
                key={data.id}
                className="flex flex-col rounded-xl shadow-md [counter-increment:video]"
              >
                <Card service={data} />
              </li>
            )
          })}
        </ol>
      </Container>

      <Container size="lg" className="mt-16">
        {catMap['b3374d67-fdf7-4ce3-8721-c0b4ff56ef0b'] && (
          <h4 className="mb-6 font-serif text-4xl text-slate-900">
            {catMap['b3374d67-fdf7-4ce3-8721-c0b4ff56ef0b']}
          </h4>
        )}
        <ol
          role="list"
          className="grid grid-cols-1 gap-y-10 gap-x-8 [counter-reset:video] sm:grid-cols-2 lg:grid-cols-4"
        >
          {categories['b3374d67-fdf7-4ce3-8721-c0b4ff56ef0b'].map((data) => {
            return (
              <li
                key={data.id}
                className="flex flex-col rounded-xl shadow-md [counter-increment:video]"
              >
                <Card service={data} />
              </li>
            )
          })}
        </ol>
      </Container>

      <Container size="lg" className="mt-16">
        {catMap['12491cfc-4419-4abb-b2ed-05390910a059'] && (
          <h4 className="mb-6 font-serif text-4xl text-slate-900">
            {catMap['12491cfc-4419-4abb-b2ed-05390910a059']}
          </h4>
        )}
        <ol
          role="list"
          className="grid grid-cols-1 gap-y-10 gap-x-8 [counter-reset:video] sm:grid-cols-2 lg:grid-cols-4"
        >
          {categories['12491cfc-4419-4abb-b2ed-05390910a059'].map((data) => {
            return (
              <li
                key={data.id}
                className="flex flex-col rounded-xl shadow-md [counter-increment:video]"
              >
                <Card service={data} />
              </li>
            )
          })}
        </ol>
      </Container>
    </section>
  )
}
