import dynamic from 'next/dynamic'
import { Fragment } from 'react'

const Modal = dynamic(() => import('./modal'))
const Markdown = dynamic(() => import('./Markdown'))

export default function FaqModal({
  openFaqModal,
  setOpenFaqModal,
  content,
  title,
}) {
  return (
    <Modal open={openFaqModal} close={() => setOpenFaqModal(false)}>
      <h3 className="mb-3 text-base font-medium tracking-tight text-slate-900 before:mb-2 before:block before:font-mono before:text-sm before:text-slate-500">
        {title}
      </h3>
      <div className="prose prose-brand prose-sm">
        {content?.map((x, i) => {
          return (
            <Fragment key={`${x.id}-${i}`}>
              <Markdown
                source={x.heading}
                renderers={{
                  p: function P({ children }) {
                    return <p className="mb-2 text-base">{children}</p>
                  },
                  a: function A({ children, ...rest }) {
                    return (
                      // @ts-ignore
                      <a {...rest} className="font-medium underline">
                        {[children]}
                      </a>
                    )
                  },
                }}
              />
              <Markdown
                source={x.body}
                renderers={{
                  p: function P({ children }) {
                    return <p className="mb-6 text-base">{children}</p>
                  },
                  a: function A({ children, ...rest }) {
                    return (
                      // @ts-ignore
                      <a {...rest} className="font-medium underline">
                        {[children]}
                      </a>
                    )
                  },
                }}
              />
            </Fragment>
          )
        })}
      </div>
    </Modal>
  )
}
