export default function (link) {
  return (
    <section className="pb-16 pt-4">
      <div className="mx-auto px-4 text-lg tracking-tight text-slate-700  sm:px-6 md:max-w-2xl md:px-4 lg:max-w-5xl lg:px-12 ">
        <p className="font-serif text-4xl leading-snug text-slate-900 text-center my-12">
          Location
        </p>
        <div className="flex w-full flex-col aspect-w-16 aspect-h-9 relative aspect-w-16 aspect-h-9">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2482.7966289460774!2d-0.1484213842296789!3d51.516946979636664!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48761ad48fedc575%3A0x695fe30c6204f0a8!2s1%20Harley%20St%2C%20London%20W1G%209QD!5e0!3m2!1sen!2suk!4v1667211411230!5m2!1sen!2suk"
              width="800"
              height="450"
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              className="absolute inset-0 w-full h-full mx-auto block overflow-hidden rounded-lg"
            />
        </div>
      </div>
    </section>
  )
}
