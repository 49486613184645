import dynamic from 'next/dynamic'

const Modal = dynamic(() => import('./modal'))
const Markdown = dynamic(() => import('./Markdown'))

export default function InfoModal({
  openInfoModal,
  setOpenInfoModal,
  content,
  title,
  setOpenFaqModal,
  faqs,
}) {

  return (
    <Modal open={openInfoModal} close={() => setOpenInfoModal(false)}>
      <h3 className="mb-3 text-base font-medium tracking-tight text-slate-900 before:mb-2 before:block before:font-mono before:text-sm before:text-slate-500">
        {title}
      </h3>
      <div className="prose prose-brand prose-sm">
        <Markdown
          source={content.body?.replaceAll('/products/', 'https://charmelle.london/products/')}
          
          renderers={{
            p: function P({ children }) {
              return <p className="mb-2 text-base">{children}</p>
            },
            a: function A({ children, ...rest }) {
              return (
                // @ts-ignore
                <a 
                target="_blank"
                {...rest} 
                className="font-medium underline">
                  {[children]}
                </a>
              )
            },
          }}
        />
        <Markdown
          source={content.highlight?.replaceAll('/products/', 'https://charmelle.london/products/')}
          renderers={{
            p: function P({ children }) {
              return <p className="mb-2 text-base">{children}</p>
            },
            a: function A({ children, ...rest }) {
              return (
                // @ts-ignore
                <a 
                target="_blank"
                {...rest} 
                className="font-medium underline">
                  {[children]}
                </a>
              )
            },
          }}
        />
        <Markdown
          source={content.callout?.replaceAll('/products/', 'https://charmelle.london/products/')}
          renderers={{
            p: function P({ children }) {
              return <p className="mb-2 text-base">{children}</p>
            },
            a: function A({ children, ...rest }) {
              return (
                // @ts-ignore
                <a 
                target="_blank"
                {...rest} 
                className="font-medium underline">
                  {[children]}
                </a>
              )
            },
          }}
        />
      </div>
      {faqs && (
      <button
        onClick={() => 
            {setOpenInfoModal(false)
            setOpenFaqModal(true)}
        }
        className="group relative flex w-full items-center justify-between py-1 text-left"
      >
        <span className="mt-0 text-sm text-slate-600 underline">FAQs</span>
      </button>
      )}
    </Modal>
  )
}
