import Header from 'components/header'
import Footer from 'components/Footer'
import Maps from 'components/Maps'
import { Hero } from 'components/Hero'
import { Introduction } from 'components/Introduction'
import { Screencasts } from 'components/Screencasts'
import ApiClient from '../utils/client'
import { getServicesById } from '../queries/services'
import { useState } from 'react'
import Stories from '../components/Stories'
import Booking from '../components/book'

export default function Home(props) {

  const [totalItems, setTotalItems] = useState([])

  function handlePanelClose() {
    setPanelOpen((panelOpen) => (panelOpen = !panelOpen))
  }

  return (
    <>
      <Header handlePanelClose={handlePanelClose} totalItemsArr={totalItems} />
      <Hero />
      <Screencasts
        data={props.resource}
        
      />
      <Introduction />
      <Maps />
      <Stories />
      <Footer />
      <Booking />
    </>
  )
}

export const getStaticProps = async (context) => {
  const client = ApiClient()
  let query = getServicesById

  let variables = {
    ids: [
      "b593caa0-7232-4937-81bf-da7b8aa78643",
      "9549cb3d-aacc-4865-aa11-4e2428db33fb"
    ],
  }
  let pageData
  try {
    pageData = await client.query({
      query,
      variables,
    })
    console.log(pageData, 'pageData')
  } catch (e) {
    console.log(e)
  }

  return {
    props: {
      ...pageData.data,
    }, // will be passed to the page component as props
  }
}
