import { GridPattern } from 'components/GridPattern'

export function Hero() {
  return (
    <header className="overflow-hidden bg-slate-100 shadow lg:bg-transparent lg:px-5">
      <div className="mx-auto grid max-w-6xl grid-cols-1 grid-rows-[auto_1fr] gap-y-6 pt-16 md:pt-20 lg:grid-cols-12 lg:gap-y-4 lg:px-3 lg:pb-12 lg:pt-20">
        <div className="relative flex items-end lg:col-span-3 lg:row-span-2">
          <div className="rounded-br-6xl absolute -top-20 -bottom-12 left-0 right-1/2 z-10 bg-brand-600 text-white/10 md:bottom-8 lg:-inset-y-32 lg:right-full lg:left-[-100vw] lg:-mr-40">
            <GridPattern
              x="100%"
              y="100%"
              patternTransform="translate(112 64)"
            />
          </div>
        </div>
        <div className="relative z-10 bg-white pt-16 lg:col-span-7 lg:bg-transparent lg:pt-0 lg:pl-12">
          <div className="mx-auto px-4 sm:px-6 md:max-w-2xl md:px-4 lg:px-0">
            <h1 className="font-serif text-5xl  text-slate-900 sm:text-6xl">
              Charmelle London Now At Harley Street
            </h1>
            <p className="mt-4 text-3xl text-slate-600">
              Expertly delivered treatments from our expert team now available
              at Harley Street on selected days each month
            </p>
            <p className="mt-4 mb-16 text-xl tracking-tight text-slate-700">
              Book a treatment from our range today to secure your appointment
            </p>
          </div>
        </div>
      </div>
    </header>
  )
}
