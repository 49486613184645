export default function Stories() {
  return (
    <section className="bg-brand-800">
      <div className="mx-auto max-w-7xl md:grid md:grid-cols-2 md:px-6 lg:px-8">
        <div className="py-12 px-4 sm:px-6 md:flex md:flex-col md:border-r md:border-brand-900 md:py-16 md:pl-0 md:pr-10 lg:pr-16">
          <q className="mt-6 md:flex md:flex-grow md:flex-col">
            <div className="relative text-lg font-medium text-white md:flex-grow">
              <svg
                className="absolute top-0 left-0 h-8 w-8 -translate-x-3 -translate-y-2 transform text-brand-600"
                fill="currentColor"
                viewBox="0 0 32 32"
                aria-hidden="true"
              >
                <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
              </svg>
              <p className="relative">
                Had 3 hydra facials at Charmelle London so far and I can&apos;t
                wait to go back. The facial itself is so relaxing (I almost fall
                asleep every time) and my skin feels/ looks amazing after but
                the staff are even better - they are lovely and welcoming and
                make sure they know exactly what you want out of your
                experience, can&apos;t recommend Charmelle London enough!
              </p>
            </div>
            <footer className="mt-8">
              <div className="flex items-center">
                <div className="inline-flex flex-shrink-0 rounded-full border-2 border-white">
                  <img
                    className="h-12 w-12 rounded-full"
                    src="https://lh3.googleusercontent.com/a-/ACNPEu95cAGbz8neuozrOy4xVVcT-Fwde3SjltfluHm-Cw=s40-c-c0x00000000-cc-rp-mo-br100"
                    alt=""
                  />
                </div>
                <div className="ml-4">
                  <div className="text-base font-medium text-white">
                    Jordan O&apos;Driscoll
                  </div>
                </div>
              </div>
            </footer>
          </q>
        </div>
        <div className="border-t-2 border-brand-900 py-12 px-4 sm:px-6 md:border-t-0 md:border-l md:py-16 md:pr-0 md:pl-10 lg:pl-16">
          <q className="mt-6 md:flex md:flex-grow md:flex-col">
            <div className="relative text-lg font-medium text-white md:flex-grow">
              <svg
                className="absolute top-0 left-0 h-8 w-8 -translate-x-3 -translate-y-2 transform text-brand-600"
                fill="currentColor"
                viewBox="0 0 32 32"
              >
                <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
              </svg>
              <p className="relative">
                Have been receiving facial peels from Charmelle London for
                chickenpox scars that left my face in a bad way. The ladies have
                been lovely! From the consultation to now the treatments. No one
                tried to upsell me anything and they&apos;ve been incredibly
                kind. Happy to be going there!
              </p>
            </div>
            <footer className="mt-8">
              <div className="flex items-center">
                <div className="inline-flex flex-shrink-0 rounded-full border-2 border-white">
                  <img
                    className="h-12 w-12 rounded-full"
                    src="https://lh3.googleusercontent.com/a-/ACNPEu9tGp2dco6j3sMwXh2Hsr7oL4ymhCQXibRGFDQx0g=s40-c-c0x00000000-cc-rp-mo-br100"
                    alt=""
                  />
                </div>
                <div className="ml-4">
                  <div className="text-base font-medium text-white">
                    Majean G
                  </div>
                </div>
              </div>
            </footer>
          </q>
        </div>
      </div>
    </section>
  )
}
