import { Container } from 'components/Container'

export function Introduction() {
  return (
    <section
      id="introduction"
      aria-label="Introduction"
      className="pt-20 pb-10 sm:pb-10 md:pt-20 lg:py-16 bg-gray-50 shadow"
    >
      <Container className=" mx-auto px-4 text-lg  text-slate-700 sm:px-6 md:max-w-2xl md:px-4 lg:max-w-5xl">
        <p className="font-serif text-4xl text-slate-900 leading-snug">
          Our team is comprised of experts, each with many years of experience
          within their fields of speciality, that deliver services to our
          clients at unparalleled levels of client satisfaction.
        </p>
        <p className="mt-6 text-slate-800">
          Excellence, expertise & experience are at the heart of everything we
          do. Customer satisfaction is our number one goal - we believe that
          everyone is different and therefore work with you to create the right
          treatment and homecare, tailored to your needs and goals.
        </p>
        <p className="mt-4 text-lg text-slate-800">
          We pride ourselves on the highest quality levels of treatment using
          the latest, industry leading equipment and materials in each of our
          services.
        </p>
        <p className="mt-4 text-lg text-slate-800">
          Book your next treatment with Charmelle London to receive:
        </p>
        <div className="my-12">
          <ul className="border-gold-500 mt-8 mb-16 flex list-none flex-wrap rounded-md border shadow-md bg-white">
            <li className="border-gold-500 flex w-full flex-col items-center justify-center p-4 text-center md:w-1/2 md:border-r-0">
              <div className="mb-2 flex">
                <svg
                  xmlns="https://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="#c3a46e"
                >
                  <path d="m12 .587 3.668 7.568L24 9.306l-6.064 5.828 1.48 8.279L12 19.446l-7.417 3.967 1.481-8.279L0 9.306l8.332-1.151z"></path>
                </svg>
                <svg
                  xmlns="https://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="#c3a46e"
                >
                  <path d="m12 .587 3.668 7.568L24 9.306l-6.064 5.828 1.48 8.279L12 19.446l-7.417 3.967 1.481-8.279L0 9.306l8.332-1.151z"></path>
                </svg>
                <svg
                  xmlns="https://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="#c3a46e"
                >
                  <path d="m12 .587 3.668 7.568L24 9.306l-6.064 5.828 1.48 8.279L12 19.446l-7.417 3.967 1.481-8.279L0 9.306l8.332-1.151z"></path>
                </svg>
                <svg
                  xmlns="https://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="#c3a46e"
                >
                  <path d="m12 .587 3.668 7.568L24 9.306l-6.064 5.828 1.48 8.279L12 19.446l-7.417 3.967 1.481-8.279L0 9.306l8.332-1.151z"></path>
                </svg>
                <svg
                  xmlns="https://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="#c3a46e"
                >
                  <path d="m12 .587 3.668 7.568L24 9.306l-6.064 5.828 1.48 8.279L12 19.446l-7.417 3.967 1.481-8.279L0 9.306l8.332-1.151z"></path>
                </svg>
              </div>
              <span className="font-serif text-gray-900">Expertise</span>
              <span>Qualified experts with multiple years of experience</span>
            </li>
            <li className="border-gold-500 flex w-full flex-col items-center justify-center border-t p-4 text-center md:w-1/2 md:border-t-0 md:border-l">
              <div className="mb-2 flex">
                <svg
                  xmlns="https://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="#c3a46e"
                >
                  <path d="m12 .587 3.668 7.568L24 9.306l-6.064 5.828 1.48 8.279L12 19.446l-7.417 3.967 1.481-8.279L0 9.306l8.332-1.151z"></path>
                </svg>
                <svg
                  xmlns="https://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="#c3a46e"
                >
                  <path d="m12 .587 3.668 7.568L24 9.306l-6.064 5.828 1.48 8.279L12 19.446l-7.417 3.967 1.481-8.279L0 9.306l8.332-1.151z"></path>
                </svg>
                <svg
                  xmlns="https://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="#c3a46e"
                >
                  <path d="m12 .587 3.668 7.568L24 9.306l-6.064 5.828 1.48 8.279L12 19.446l-7.417 3.967 1.481-8.279L0 9.306l8.332-1.151z"></path>
                </svg>
                <svg
                  xmlns="https://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="#c3a46e"
                >
                  <path d="m12 .587 3.668 7.568L24 9.306l-6.064 5.828 1.48 8.279L12 19.446l-7.417 3.967 1.481-8.279L0 9.306l8.332-1.151z"></path>
                </svg>
                <svg
                  xmlns="https://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="#c3a46e"
                >
                  <path d="m12 .587 3.668 7.568L24 9.306l-6.064 5.828 1.48 8.279L12 19.446l-7.417 3.967 1.481-8.279L0 9.306l8.332-1.151z"></path>
                </svg>
              </div>
              <span className="font-serif text-gray-900">Choice</span>
              <span>
                Extensive range of services all tailored to each client
              </span>
            </li>
            <li className="border-gold-500 flex w-full flex-col items-center justify-center border-t p-4 text-center md:w-1/2 md:border-r-0">
              <div className="mb-2 flex">
                <svg
                  xmlns="https://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="#c3a46e"
                >
                  <path d="m12 .587 3.668 7.568L24 9.306l-6.064 5.828 1.48 8.279L12 19.446l-7.417 3.967 1.481-8.279L0 9.306l8.332-1.151z"></path>
                </svg>
                <svg
                  xmlns="https://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="#c3a46e"
                >
                  <path d="m12 .587 3.668 7.568L24 9.306l-6.064 5.828 1.48 8.279L12 19.446l-7.417 3.967 1.481-8.279L0 9.306l8.332-1.151z"></path>
                </svg>
                <svg
                  xmlns="https://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="#c3a46e"
                >
                  <path d="m12 .587 3.668 7.568L24 9.306l-6.064 5.828 1.48 8.279L12 19.446l-7.417 3.967 1.481-8.279L0 9.306l8.332-1.151z"></path>
                </svg>
                <svg
                  xmlns="https://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="#c3a46e"
                >
                  <path d="m12 .587 3.668 7.568L24 9.306l-6.064 5.828 1.48 8.279L12 19.446l-7.417 3.967 1.481-8.279L0 9.306l8.332-1.151z"></path>
                </svg>
                <svg
                  xmlns="https://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="#c3a46e"
                >
                  <path d="m12 .587 3.668 7.568L24 9.306l-6.064 5.828 1.48 8.279L12 19.446l-7.417 3.967 1.481-8.279L0 9.306l8.332-1.151z"></path>
                </svg>
              </div>
              <span className="font-serif text-gray-900">Dedicated to you</span>
              <span>Bespoke treatment plans and service for each client</span>
            </li>
            <li className="border-gold-500 flex w-full flex-col items-center justify-center border-t p-4 text-center md:w-1/2 md:border-l">
              <div className="mb-2 flex">
                <svg
                  xmlns="https://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="#c3a46e"
                >
                  <path d="m12 .587 3.668 7.568L24 9.306l-6.064 5.828 1.48 8.279L12 19.446l-7.417 3.967 1.481-8.279L0 9.306l8.332-1.151z"></path>
                </svg>
                <svg
                  xmlns="https://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="#c3a46e"
                >
                  <path d="m12 .587 3.668 7.568L24 9.306l-6.064 5.828 1.48 8.279L12 19.446l-7.417 3.967 1.481-8.279L0 9.306l8.332-1.151z"></path>
                </svg>
                <svg
                  xmlns="https://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="#c3a46e"
                >
                  <path d="m12 .587 3.668 7.568L24 9.306l-6.064 5.828 1.48 8.279L12 19.446l-7.417 3.967 1.481-8.279L0 9.306l8.332-1.151z"></path>
                </svg>
                <svg
                  xmlns="https://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="#c3a46e"
                >
                  <path d="m12 .587 3.668 7.568L24 9.306l-6.064 5.828 1.48 8.279L12 19.446l-7.417 3.967 1.481-8.279L0 9.306l8.332-1.151z"></path>
                </svg>
                <svg
                  xmlns="https://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="#c3a46e"
                >
                  <path d="m12 .587 3.668 7.568L24 9.306l-6.064 5.828 1.48 8.279L12 19.446l-7.417 3.967 1.481-8.279L0 9.306l8.332-1.151z"></path>
                </svg>
              </div>
              <span className="font-serif text-gray-900">Safety</span>
              <span>Industry vetted and approved technology</span>
            </li>
          </ul>
        </div>
      </Container>
    </section>
  )
}
